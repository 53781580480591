* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  /* border: thin solid red; */
}

body {
  background-color: #181717;
  color: white;
  /* width: 100vh; */
}

.cont {
  margin: 0px;
  width: 100%;
}


/*_____HEADER STYLES____________*/

nav {
  display: flex;
  justify-content: space-between;
  height: 50px;
  /* border: thin solid; */
}
nav>div {
  display: flex;
  justify-content: center;
  align-items: center;
}

nav>div>ul {
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}

nav>div>ul>li {
  margin: 5px;
}

.sharklogo {
  height: 30px;
}

/*__________add Task Area ___________*/

.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1000px;
  margin: auto;
  padding: 10px;
  /* border: thin solid red; */
}

.add_task {
  /* border: thin solid red; */
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #2E2E2E;
  height: 50px;
  border-radius: 10px;
}

.add_task>input {
  background-color: inherit;
  font-size: 20px;
  border-radius: 20px;
  padding: 0px 10px;
  width: 100%;
  border: none;
  color: white;
}

.add_task input:focus {
  outline: none;
}

.add_task>.addbtn {
  background-color: #181717;
  border-radius: 10px;
  padding: 5px 10px;
  font-size: 20px;
  margin: 5px;
  color: white;
}

.add_task>.addbtn:hover {
  cursor: pointer;
  border: thin solid white;
}


/*____________Task List styles _____________*/

.task_list {
  list-style-type: none;
  width: 100%;
  padding: 0px;
}
.task_list>li{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 5px;
  border-radius: 10px;
  padding: 5px;
  font-size: 20px;
}
.task_list>li:hover{
  /* border: thin solid wheat; */
  background-color: #2d2b2b;
}
.taskname {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0px 5px;
  height: 100%;
  /* border: thin solid red; */
}

/*_______________Login form ______________*/
.login{
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  padding: 10px;
  /* border: thin solid red; */
}

.login>form {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.login>div>input {
  margin-bottom: 10px;
}

.pass-label {
  /* border: thin solid red; */
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding: 5px;
}

.loader-cont {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 1000;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.response-msg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 5px;
  background-color: #077fcf;
  color: white;
  font-size: 20px;
  border-radius: 10px;
  margin: 5px;
  animation: air-drop-notification 1s ease-in-out;
}

@keyframes air-drop-notification {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  50% {
    transform: translateY(10%);
    opacity: 1;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.success-msg {
  background-color: #00ff00;
}
.error-msg {
  background-color: #ff0000;
}